import React from 'react'
import NavbarResume from '../components/NavbarResume'
import Footer from '../components/Footer'
const resume = () => {
  return (
    <>
      <NavbarResume/>
      <Footer/>
    </>
  )
}

export default resume